  
  .customer-checkbox {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px
  }

  .customer-checkbox .form-group__label-group {
    padding-left: 0px !important;
  }

  .customer-settings-card .p {
    margin-bottom: 0px !important;
  }

  .no-bottom-margin {
    margin-bottom: 0px !important;
  }

  .header-bar .btn-default  {
    display: none !important;
  }
