  
  .reminders-checkbox {
    display: inline-block;
  }

  .reminders-checkbox .form-group__label-group {
    padding-left: 0px !important;
  }

  .reminders-radio {
    display: inline-block;
  }

  .reminders-radio .radio {
    display: inline-block !important;
    padding-right: 30px
  }

  .reminders-radio .form-group__label-group {
    padding-left: 0px !important;
  }

  .placeholders-group .btn-default {
    margin-bottom: 5px !important;
  }

  .placeholders-group .label {
    padding-top: 7px !important;
  }

  .offsetContainer .radio {
    padding-top: 5px !important;
  }

  .algorithm-description {
    font-size: 12px !important;
    margin-bottom: 0px !important;
    text-align: left;
  }

  .algorithm-label {
    text-align: left;
    margin-bottom: 0px !important;
  }

  .schedule-button {
    margin-left: 8px !important;
  }

 li.flx-tabs__item:last-child > a::after    {
    content: "New";
    background-color: rgba(0, 170, 10, 0.2);
    color: #007747;
    border-radius: 1.2rem;
    padding: 0.1rem 1rem;
    font-size: 14px;
    margin-left: 5px;
  }